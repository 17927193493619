@import url(/assets/plugins/bootstrap/css/bootstrap.min.css);
@import url(/assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css);
/*
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * www.urbenseyboth.ch
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2016
 */
@font-face {
  font-family: "Circular-Bold";
  src: url("/assets/fonts/lineto-circular-bold.eot");
  src: url("/assets/fonts/lineto-circular-bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/lineto-circular-bold.woff2") format("woff2"), url("/assets/fonts/lineto-circular-bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Circular-Bolditalic";
  src: url("/assets/fonts/lineto-circular-bolditalic.eot");
  src: url("/assets/fonts/lineto-circular-bolditalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/lineto-circular-bolditalic.woff2") format("woff2"), url("/assets/fonts/lineto-circular-bolditalic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Circular-Book";
  src: url("/assets/fonts/lineto-circular-book.eot");
  src: url("/assets/fonts/lineto-circular-book.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/lineto-circular-book.woff2") format("woff2"), url("/assets/fonts/lineto-circular-book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Circular-Bookitalic";
  src: url("/assets/fonts/lineto-circular-bookitalic.eot");
  src: url("/assets/fonts/lineto-circular-bookitalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/lineto-circular-bookitalic.woff2") format("woff2"), url("/assets/fonts/lineto-circular-bookitalic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
  /* Magnific Popup CSS */
  .mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #0b0b0b;
    opacity: 0.8;
  }
  .mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: none !important;
    -webkit-backface-visibility: hidden;
  }
  .mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    box-sizing: border-box;
  }
  .mfp-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  .mfp-align-top .mfp-container:before {
    display: none;
  }
  .mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045;
  }
  .mfp-inline-holder .mfp-content,
  .mfp-ajax-holder .mfp-content {
    width: 100%;
    cursor: auto;
  }
  .mfp-ajax-cur {
    cursor: progress;
  }
  .mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
  }
  .mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
  }
  .mfp-auto-cursor .mfp-content {
    cursor: auto;
  }
  .mfp-close,
  .mfp-arrow,
  .mfp-preloader,
  .mfp-counter {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  .mfp-loading.mfp-figure {
    display: none;
  }
  .mfp-hide {
    display: none !important;
  }
  .mfp-preloader {
    color: #CCC;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -0.8em;
    left: 8px;
    right: 8px;
    z-index: 1044;
  }
  .mfp-preloader a {
    color: #CCC;
  }
  .mfp-preloader a:hover {
    color: #FFF;
  }
  .mfp-s-ready .mfp-preloader {
    display: none;
  }
  .mfp-s-error .mfp-content {
    display: none;
  }
  button.mfp-close,
  button.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: 1046;
    box-shadow: none;
    touch-action: manipulation;
  }
  button::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
  .mfp-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: 0.65;
    padding: 0 0 18px 10px;
    color: #FFF;
    font-style: normal;
    font-size: 28px;
    font-family: Arial, Baskerville, monospace;
  }
  .mfp-close:hover,
  .mfp-close:focus {
    opacity: 1;
  }
  .mfp-close:active {
    top: 1px;
  }
  .mfp-close-btn-in .mfp-close {
    color: #333;
  }
  .mfp-image-holder .mfp-close,
  .mfp-iframe-holder .mfp-close {
    color: #FFF;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%;
  }
  .mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #CCC;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
  }
  .mfp-arrow {
    position: absolute;
    opacity: 0.65;
    margin: 0;
    top: 50%;
    margin-top: -55px;
    padding: 0;
    width: 90px;
    height: 110px;
    -webkit-tap-highlight-color: transparent;
  }
  .mfp-arrow:active {
    margin-top: -54px;
  }
  .mfp-arrow:hover,
  .mfp-arrow:focus {
    opacity: 1;
  }
  .mfp-arrow:before,
  .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent;
  }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px;
  }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7;
  }
  .mfp-arrow-left {
    left: 0;
  }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px;
  }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F;
  }
  .mfp-arrow-right {
    right: 0;
  }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px;
  }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F;
  }
  .mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px;
  }
  .mfp-iframe-holder .mfp-close {
    top: -40px;
  }
  .mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%;
  }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000;
  }
  /* Main image in popup */
  img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 40px 0 40px;
    margin: 0 auto;
  }
  /* The shadow behind the image */
  .mfp-figure {
    line-height: 0;
  }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444;
  }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px;
  }
  .mfp-figure figure {
    margin: 0;
  }
  .mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto;
  }
  .mfp-title {
    text-align: left;
    line-height: 18px;
    color: #F3F3F3;
    word-wrap: break-word;
    padding-right: 36px;
  }
  .mfp-image-holder .mfp-content {
    max-width: 100%;
  }
  .mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer;
  }
}

@media only screen and (min-width: 320px) and (max-width: 800px) and (orientation: landscape), only screen and (min-width: 320px) and (max-height: 300px) {
  /**
            * Remove all paddings around the image on small screen
            */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media only screen and (min-width: 320px) {
  /*!
    * IE10 viewport hack for Surface/desktop Windows 8 bug
    * Copyright 2014-2015 Twitter, Inc.
    * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
    */
  /*
    * See the Getting Started docs for more information:
    * http://getbootstrap.com/getting-started/#support-ie10-width
    */
  @-ms-viewport {
    width: device-width;
  }
  @-o-viewport {
    width: device-width;
  }
  @viewport {
    width: device-width;
  }
  /* BODY */
  body {
    margin: 0;
    padding-top: 80px;
    padding-bottom: 40px;
    color: #333;
  }
  ::selection {
    background: #dbdbdb;
  }
  ::-moz-selection {
    background: #dbdbdb;
  }
  /* Cookie Info */
  .cc-revoke, .cc-window {
    font-family: "Circular-Book", AdobeBlank, sans-serif;
  }
  /* Splash */
  .splash {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999999999;
    background: #fff;
    text-align: center;
  }
  .splash > .splash-logo {
    max-width: 560px;
    height: auto;
  }
  /* Carousel */
  .carousel-container {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: #000;
    z-index: 99999999;
  }
  .item {
    height: 800px;
  }
  .item img {
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .carousel-container .closer {
    position: absolute;
    right: 28px;
    top: 18px;
    width: 60px;
    height: 32px;
    z-index: 999999999;
  }
  .carousel-container .closer:hover {
    opacity: 0.6;
  }
  .carousel-container .closer:before,
  .carousel-container .closer:after {
    position: absolute;
    left: 35px;
    content: ' ';
    height: 48px;
    width: 1px;
    background-color: #fff;
  }
  .carousel-container .closer:before {
    transform: rotate(67deg);
  }
  .carousel-container .closer:after {
    transform: rotate(-67deg);
  }
  .carousel-control.left,
  .carousel-control.right {
    background: none;
  }
  /* Accordion */
  .panel {
    border: none;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .panel-default > .panel-heading {
    background: transparent;
    border-bottom: none;
    padding-left: 0;
    padding-bottom: 40px;
  }
  .panel-group .panel-heading + .panel-collapse > .panel-body, .panel-group .panel-heading + .panel-collapse > .list-group {
    border-top: none;
  }
  .panel-body {
    padding: 0;
  }
  /* Toolbox */
  .toolbox-wrapper {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
    z-index: 999;
    background: transparent;
  }
  .toolbox {
    margin-left: 20px;
    color: #111;
    display: none;
  }
  .toolbox a {
    line-height: 60px;
    vertical-align: middle;
    color: #111;
    border-bottom: 1px solid #111;
  }
  .toolbox a:active,
  .toolbox a:focus,
  .toolbox a:hover {
    color: #878787;
    border-bottom: 1px solid #111;
    text-decoration: none;
  }
  .open {
    bottom: 0;
    -webkit-transition: all 400ms linear;
    -moz-transition: all 400ms linear;
    -ms-transition: all 400ms linear;
    -o-transition: all 400ms linear;
    transition: all 400ms linear;
  }
  .default-grey {
    color: #878787;
  }
  /* Spacing and sizes */
  .height-100 {
    height: 100px !important;
  }
  .height-150 {
    height: 150px !important;
  }
  .height-200 {
    height: 200px !important;
  }
  .height-250 {
    height: 250px !important;
  }
  .height-300 {
    height: 300px !important;
  }
  .height-350 {
    height: 350px !important;
  }
  .height-400 {
    height: 400px !important;
  }
  .height-450 {
    height: 450px !important;
  }
  .height-500 {
    height: 500px !important;
  }
  .height-550 {
    height: 550px !important;
  }
  .height-600 {
    height: 600px !important;
  }
  .no-padding {
    padding-left: 0;
    padding-right: 0;
  }
  .margin-bottom-60 {
    margin-bottom: 60px;
  }
  .margin-bottom-10 {
    margin-bottom: 10px;
  }
  .size-20 {
    font-size: 20px !important;
    line-height: 26px !important;
  }
  .size-40 {
    font-size: 40px !important;
    line-height: 46px !important;
  }
  .asset-text-padding {
    padding-left: 30px;
    padding-right: 30px;
  }
  /* Maps */
  .map-container {
    overflow: hidden;
    position: relative;
    height: 0;
  }
  .map-responsive {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  /* Tables */
  .table.table-noborder > thead > tr > th,
  .table.table-noborder > tbody > tr > th,
  .table.table-noborder > tfoot > tr > th,
  .table.table-noborder > thead > tr > td,
  .table.table-noborder > tbody > tr > td,
  .table.table-noborder > tfoot > tr > td {
    border: none !important;
  }
  /* Links begin */
  a {
    color: #444;
    -webkit-transition: all 250ms linear;
    -moz-transition: all 250ms linear;
    -ms-transition: all 250ms linear;
    -o-transition: all 250ms linear;
    transition: all 250ms linear;
  }
  a:hover {
    color: #878787;
    -webkit-transition: all 250ms linear;
    -moz-transition: all 250ms linear;
    -ms-transition: all 250ms linear;
    -o-transition: all 250ms linear;
    transition: all 250ms linear;
  }
  a:active, a:focus {
    outline: 0;
  }
  .default-link:link,
  .default-link:active,
  .default-link:visited,
  .default-link:focus {
    color: #878787;
    border-bottom: 2px solid #444;
    text-decoration: none;
  }
  .default-link:hover {
    color: #444;
    border-bottom: 2px solid #444;
    text-decoration: none;
  }
  .link-no-styles {
    text-decoration: none;
  }
  .link-no-styles:link,
  .link-no-styles:active,
  .link-no-styles:visited,
  .link-no-styles:focus {
    text-decoration: none !important;
  }
  #address h4 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  #address h4:first-child {
    margin-top: 0;
    margin-bottom: 8px;
  }
  /* YEAR Filter */
  .year-filter {
    border-color: #fff !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    outline: none;
  }
  #portfolio .pull-right p {
    margin: 0;
    color: #878787;
  }
  /* Sections */
  section#profile > .row > div > .row > div {
    margin-bottom: 60px;
  }
  /* Navbar begin */
  header {
    height: 82px;
    position: fixed;
    top: 0;
    transition: top 0.2s ease-in-out;
    width: 100%;
    z-index: 999;
  }
  .nav-up {
    top: -82px;
  }
  .navbar {
    background: #fff;
    border-radius: 0;
  }
  .navbar-header {
    float: none;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: none;
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-collapse.collapsing {
    -webkit-transition: height 0.01s;
    -moz-transition: height 0.01s;
    -ms-transition: height 0.01s;
    -o-transition: height 0.01s;
    transition: height 0.01s;
  }
  .navbar-nav {
    float: none !important;
    margin-top: 7.5px;
    text-align: right;
  }
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding: 12px 0;
  }
  .navbar-nav > li > a:focus,
  .navbar-nav > li > a:hover {
    text-decoration: none;
    background-color: transparent;
    color: #878787;
  }
  .navbar-nav > li.nav-link-active > a {
    color: #878787;
  }
  .navbar-nav > li.language-link.active {
    display: inline-block;
    border-bottom: solid #444;
  }
  .navbar-nav > li.language-link > a {
    padding-bottom: 0;
  }
  .collapse.in {
    display: block !important;
  }
  .navbar-toggle {
    width: 50px;
    height: 40px;
    display: block;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
  }
  .navbar-toggle span {
    display: block;
    position: absolute;
    height: 1px;
    width: 100%;
    background: #444;
    border-radius: 1px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .15s ease-in-out;
    -moz-transition: .15s ease-in-out;
    -o-transition: .15s ease-in-out;
    transition: .15s ease-in-out;
  }
  .navbar-toggle span:nth-child(1) {
    top: 0;
  }
  .navbar-toggle span:nth-child(2), .navbar-toggle span:nth-child(3) {
    top: 8px;
  }
  .navbar-toggle span:nth-child(4) {
    top: 16px;
  }
  .navbar-toggle.open span:nth-child(1) {
    top: 8px;
    width: 0%;
    left: 50%;
  }
  .navbar-toggle.open span:nth-child(2) {
    -webkit-transform: rotate(26deg);
    -moz-transform: rotate(26deg);
    -o-transform: rotate(26deg);
    transform: rotate(26deg);
  }
  .navbar-toggle.open span:nth-child(3) {
    -webkit-transform: rotate(-26deg);
    -moz-transform: rotate(-26deg);
    -o-transform: rotate(-26deg);
    transform: rotate(-26deg);
  }
  .navbar-toggle.open span:nth-child(4) {
    top: 8px;
    width: 0%;
    left: 50%;
  }
  .hider {
    position: fixed;
    left: 0 !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    z-index: 99;
    background: #fff;
  }
  /* Fonts */
  section h2 {
    margin-bottom: 20px;
  }
  section h3 {
    font-size: 90%;
    color: #878787;
    margin: 5px 0 0 0;
  }
  #text h3:not(:first-child) {
    margin: 25px 0 0 0;
  }
  section .sidebar h3 {
    margin: 5px 0 0 0 !important;
  }
  section h4 {
    margin: 20px 0 5px 0;
  }
  section .item-box h4 {
    margin-top: 8px;
  }
  section p {
    color: #555;
    line-height: 160%;
  }
  section ul {
    color: #555;
  }
  section h2:first-child,
  section h3:first-child {
    margin-top: 0;
  }
  /* Mixitup */
  .btn-mix-filter {
    background: none;
    border-radius: 0;
    padding: 0;
    color: #878787;
    border-bottom: 1px solid #333;
    outline: none;
    line-height: 124%;
    margin-bottom: 10px;
  }
  .btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {
    outline: none !important;
  }
  .mixitup-control-active {
    color: #333 !important;
  }
  .mixitup-title-value {
    text-transform: capitalize;
  }
  .nav > li > a:hover, .nav > li > a:focus {
    background: #fff;
  }
  /* Item Box */
  .item-box {
    overflow: hidden;
    margin: 0 0 30px 0;
    position: relative;
    box-shadow: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  .item-box figure {
    width: 100%;
    display: block;
    margin-bottom: 0;
    overflow: hidden;
    position: relative;
    text-align: center;
  }
  /* hover */
  .item-box .item-hover {
    opacity: 0;
    filter: alpha(opacity=0);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    color: #fff;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }
  .item-box .item-hover,
  .item-box .item-hover button,
  .item-box .item-hover a {
    color: #fff;
  }
  .item-box .item-hover .inner {
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    top: 50%;
    margin-top: -10px;
    margin-bottom: 0;
    width: 100%;
    z-index: 100;
    line-height: 23px;
  }
  .item-box:hover .item-hover {
    opacity: 1;
    filter: alpha(opacity=100);
  }
  .item-box .item-hover .inner .ico-rounded > span {
    color: #666;
    background-color: #fff;
    width: 50px;
    height: 50px;
    line-height: 50px !important;
    margin: -20px 5px 0 5px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }
  .nav-pills > li > a {
    color: #878787;
    background-color: transparent;
    border-bottom: 1px solid #444;
    border-radius: 0;
    padding: 2px 0 0 0;
    line-height: 120%;
  }
  .nav-pills > li > a:hover,
  .nav-pills > li.active > a,
  .nav-pills > li.active > a:hover,
  .nav-pills > li.active > a:focus {
    color: #444;
    border-bottom-color: #444;
    background-color: transparent;
  }
  .overlay.dark-5 {
    background-color: rgba(0, 0, 0, 0.5);
  }
  /* light overlay */
  .overlay.light-0 {
    background-color: rgba(255, 255, 255, 0);
  }
  .overlay.light-1 {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .overlay.light-2 {
    background-color: rgba(255, 255, 255, 0.2);
  }
  .overlay.light-3 {
    background-color: rgba(255, 255, 255, 0.3);
  }
  .overlay.light-4 {
    background-color: rgba(255, 255, 255, 0.4);
  }
  .overlay.light-5 {
    background-color: rgba(255, 255, 255, 0.5);
  }
  .overlay.light-6 {
    background-color: rgba(255, 255, 255, 0.6);
  }
  .overlay.light-7 {
    background-color: rgba(255, 255, 255, 0.7);
  }
  .overlay.light-8 {
    background-color: rgba(255, 255, 255, 0.8);
  }
  .overlay.light-9 {
    background-color: rgba(255, 255, 255, 0.9);
  }
  .overlay.light-10 {
    background-color: white;
  }
  .overlay {
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    -webkit-transition: all 0.1s;
    -moz-transition: all 0.1s;
    -o-transition: all 0.1s;
    transition: all 0.1s;
  }
  /* Mixitup end */
  /* Preloader begin */
  #preloader {
    position: fixed;
    z-index: 9999999;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #fff;
  }
  .inner {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 54px;
    height: 54px;
    margin: auto;
  }
  .page-loader {
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #fefefe;
    z-index: 100000;
  }
  #preloader span.loader {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    font-size: 10px;
    text-indent: -12345px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    border-right: 1px solid rgba(0, 0, 0, 0.08);
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    border-left: 1px solid rgba(0, 0, 0, 0.5);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-animation: spinner 700ms infinite linear;
    -moz-animation: spinner 700ms infinite linear;
    -ms-animation: spinner 700ms infinite linear;
    -o-animation: spinner 700ms infinite linear;
    animation: spinner 700ms infinite linear;
    z-index: 100001;
  }
  @-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-moz-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-o-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  /* Animations */
  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  @-webkit-keyframes fadeInUp {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  @keyframes fadeInUp {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  .fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
  }
  /* Scroll To Top */
  #toTop {
    position: fixed;
    height: 36px;
    width: 80px;
    right: 16px;
    bottom: 12px;
    display: none;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }
  #toTop .arrow {
    width: 40px;
    height: 20px;
    border-bottom: 1px solid #111;
    z-index: 9999999999999;
  }
  #toTop .arrow.arrow-left {
    position: absolute;
    right: -3px;
    -moz-transform: rotate(35deg);
    -webkit-transform: rotate(35deg);
    -o-transform: rotate(35deg);
    -ms-transform: rotate(35deg);
  }
  #toTop .arrow.arrow-right {
    position: absolute;
    -moz-transform: rotate(-35deg);
    -webkit-transform: rotate(-35deg);
    -o-transform: rotate(-35deg);
    -ms-transform: rotate(-35deg);
  }
  #toTop:hover {
    cursor: pointer;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }
  /* Show more Projects arrow */
  #showMoreProjects {
    position: fixed;
    height: 36px;
    width: 80px;
    left: 16px;
    bottom: 12px;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }
  #showMoreProjects .arrow {
    width: 40px;
    height: 20px;
    border-bottom: 1px solid #111;
    z-index: 9999999999999;
  }
  #showMoreProjects .arrow.arrow-left {
    position: absolute;
    right: 18px;
    -moz-transform: rotate(-35deg);
    -webkit-transform: rotate(-35deg);
    -o-transform: rotate(-35deg);
    -ms-transform: rotate(-35deg);
  }
  #showMoreProjects .arrow.arrow-right {
    position: absolute;
    -moz-transform: rotate(35deg);
    -webkit-transform: rotate(35deg);
    -o-transform: rotate(35deg);
    -ms-transform: rotate(35deg);
  }
  #showMoreProjects:hover {
    cursor: pointer;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }
  .logo {
    width: 180px;
    max-height: 16px;
  }
  .navbar-brand {
    width: 180px;
    height: 16px;
    margin: 30px 0 0 30px;
    padding: 0;
  }
  .navbar-toggle {
    margin: 30px 29px 0 0;
  }
  .navbar-nav {
    margin-right: 0;
  }
  .navbar-nav > li > a {
    font-size: 30px;
  }
  .navbar-nav > li.language-link.active {
    border-bottom-width: 2px;
  }
  .navbar-nav > li.language-link > a {
    font-size: 18px;
  }
  .nav-pills > li {
    margin-right: 10px;
  }
  h1 {
    margin-bottom: 20px;
    margin-top: 40px;
    font-size: 24px;
  }
}

/* Extra Small Devices, Phones */
/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .navbar-brand {
    margin: 32px 0 0 32px;
  }
  .navbar-toggle {
    margin: 30px 29px 0 0;
  }
  .navbar-nav {
    margin-right: 30px;
  }
  .navbar-nav > li > a {
    font-size: 30px;
  }
  .navbar-nav > li.language-link.active {
    border-bottom-width: 2px;
  }
  .navbar-nav > li.language-link > a {
    font-size: 18px;
  }
  h1 {
    margin-bottom: 30px;
    margin-top: 80px;
    font-size: 36px;
  }
  section h4 {
    margin: 40px 0 5px 0;
  }
  section p {
    font-size: 18px;
  }
  .short-bio {
    min-height: 250px;
  }
}

/* Medium Devices, Desktops */
/* Large Devices, Wide Screens */
@media only screen and (max-width: 767px) {
  /*#content > .container-fluid > section > div.row {*/
  /*margin-top: 30px !important;*/
  /*margin-bottom: 30px !important;*/
  /*}*/
  /*#content > .container-fluid > section > div.row:first-child {*/
  /*margin-top: 0 !important;*/
  /*}*/
  section#tiles > div > div > div > div {
    margin-bottom: 25px;
  }
  section#tiles > div > div > div > div:last-child {
    margin-bottom: 0;
  }
  section h2 {
    margin-bottom: 20px;
  }
  .carousel-container {
    padding: 0;
  }
  #single-images > div, #tiles > .row {
    margin-bottom: 25px !important;
  }
  .item-box {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 480px) {
  body {
    margin-bottom: 60px;
  }
  .splash > .splash-logo {
    max-width: 280px;
    height: auto;
  }
  .map-container.height-600 {
    height: 240px !important;
  }
}
